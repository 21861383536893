'use client';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export const InformationTooltip = ({ children, size = 20 }) => {
  return (
    <svg
      data-tooltip-content={children}
      data-tooltip-id="tool-tip-1"
      className="text-gray-500 ml-2 flex"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width={size}
      height={size}
      style={{ minWidth: size }}
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const ToolTipGeneric = ({ children, tip, id = 'tool-tip-1' }) => {
  return (
    <div data-tooltip-content={tip} data-tooltip-id={id}>
      {children}
    </div>
  );
};

export const TooltipPortal = ({
  style,
  id = 'tool-tip-1',
}: {
  style?: any;
  id?: string;
}) => (
  <ReactTooltip
    style={
      style || {
        maxWidth: '20rem',
      }
    }
    id={id}
  />
);
